@import "../ColorScheme.scss";
.Unknown {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  * {
    margin: 1rem auto;
  }
  p {
    font-size: 2rem;
    font-weight: 600;
  }
  a {
    text-decoration: underline;
    color: $red;
    font-size: 1.2rem;
    font-weight: 600;
  }
}
