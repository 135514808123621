@import "../ColorScheme.scss";
.Experience {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 98%;
  height: 100%;
  flex-direction: column;
  .Page-Title {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    h1 {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      margin: 2rem 3rem 0 0;
      font-size: 4rem;
      color: $titleText;
    }
  }
  .Experience-Container {
    width: 90%;
    letter-spacing: 2px;
    h4 {
      color: $experienceColor;
    }
    h5 {
      font-weight: 400;
    }
    p {
      font-family: "Montserrat";
      letter-spacing: 0.5px;
    }
    h4,
    p,
    .link-container {
      margin: 1rem 0 0;
    }
    .link-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      flex-wrap: wrap;
      gap: 10px;
      button {
        width: max-content;
        height: 4vh;
        font-size: 1.1rem;
        box-shadow: -3px 3px 7px $borderPrimary;
        border: none;
        background-color: $white;
        border-radius: 5px;
        padding: 0 0.5rem;
        a {
          text-decoration: none;
          color: $titleText;
        }
      }
    }
  }
}
@media (min-width: 1025px) and (max-width: 1366px) {
  .Experience {
    .Experience-Container {
      .link-container {
        button {
          height: 6vh;
        }
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 1025px) {
  .Experience {
    .Page-Title {
      h1 {
        margin: 2rem 3rem 0 0;
      }
    }
    .Experience-Container {
      .link-container {
        justify-content: flex-start;
      }
    }
  }
}
@media (max-width: 767px) {
  .Experience {
    .Page-Title {
      h1 {
        font-size: 3rem;
        margin: 1rem 1rem 0 0;
      }
    }
    .Experience-Container {
      width: 100%;
      .link-container {
        overflow: hidden;
        padding: 0.25rem;
        button {
          width: max-content;
          height: 3vh;
          font-size: 0.9rem;
          font-weight: 600;
          padding: 0 0.1rem;
          box-shadow: -2px 2px 2px $boxShadow;
        }
      }
    }
  }
}
