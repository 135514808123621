@import "../ColorScheme.scss";
.Projects {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 98%;
  height: 100%;
  flex-direction: column;

  .Page-Title {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    h1 {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      margin: 2rem 3rem 0 0;
      font-size: 4rem;
      color: $titleText;
    }
  }
  .Project-Container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 4rem;
    justify-content: center;
    align-items: flex-start;
    padding: 2rem 0;
    scroll-behavior: smooth;
    overflow-y: scroll;
    ::-webkit-scrollbar {
      display: none;
    }
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  .None-Available {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: inherit;
  }
}
@media (min-width: 1366px) {
  .Projects {
    .Project-Container {
      gap: 2rem;
      padding: 0 4rem;
    }
  }
}

@media (min-width: 768px) and (max-width: 1025px) {
  .Projects {
    .Page-Title {
      h1 {
        margin: 2rem 3rem 0 0;
      }
    }
    .Project-Container {
      grid-template-columns: repeat(1, 1fr);
      gap: 2rem;
      height: 100vh;
    }
  }
}
@media (max-width: 767px) {
  .Projects {
    .Page-Title {
      h1 {
        font-size: 3rem;
        margin: 1rem 1rem 0 0;
      }
    }
    .Project-Container {
      grid-template-columns: repeat(1, 1fr);
      gap: 2rem;
      height: 100vh;
    }
  }
}
