@import "../ColorScheme.scss";
.About {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 98%;
  height: 100%;
  flex-direction: column;

  .Page-Title {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    h1 {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      margin: 2rem 3rem 0 0;
      font-size: 4rem;
      color: $titleText;
    }
  }
  .Content-Info {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 90%;
    flex-direction: row;
    .profile-pic {
      flex: 0.4;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      img {
        width: 85%;
        height: auto;
        object-fit: cover;
      }
    }
    .profile-content {
      flex: 0.6;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: justify;
      height: 100%;
      flex-direction: column;
      font-size: 1.1rem;
      font-family: 'Montserrat';
      * {
        margin: 0.5rem 0;
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 1025px) {
  .About {
    .Page-Title {
      h1 {
        margin: 2rem 3rem 0 0;
      }
    }
    .Content-Info {
      flex-direction: column;
      .profile-pic {
        margin: 2rem 0;
        img {
          width: 50%;
          height: auto;
          object-fit: cover;
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .About {
    .Page-Title {
      h1 {
        font-size: 3rem;
        margin: 1rem 1rem 0 0;
      }
    }
    .Content-Info {
      flex-direction: column;
      .profile-pic {
        margin: 2rem 0;
        img {
          width: 80%;
          height: auto;
          object-fit: cover;
        }
      }
    }
  }
}
