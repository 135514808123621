@import "../ColorScheme.scss";
.Blog-Card {
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: -5px 5px 7px $boxShadow;
  background-color: $postBgColor;
  padding: 1.5rem;
  height: 55vh;
  width: 20vw;
  border-radius: 5px;
  a {
    text-decoration: none;
    color: $nameText;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .Blog-Title,
    .Blog-Excerpt,
    .Blog-Metadata {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0.5rem 0;
    }
    .Blog-Title {
      flex: 0.2;
      font-size: 1.5rem;
      text-align: left;
      font-family: "GrandSlang-Roman";
    }
    .Blog-Excerpt {
      flex: 0.6;
      text-align: justify;
      scrollbar-width: none;
      align-items: flex-start;
    }
    .Blog-Metadata {
      flex: 0.2;
      flex-direction: column;
      align-items: flex-end;
      font-weight: 500;
      .Blog-Categories {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        align-items: center;
        gap: 0rem 0.5rem;
        flex-wrap: wrap;
        .Blog-Category {
          margin-bottom: 0.5rem;
          background-color: $mozSelection;
          color: $white;
          padding: 0.25rem;
          border-radius: 20px;
        }
      }
    }
  }
}
@media (min-width: 1366px) {
  .Blog-Card {
    height: max-content;
  }
}
@media (min-width: 768px) and (max-width: 1025px) {
  .Blog-Card {
    height: max-content;
    width: auto;
  }
}

@media (max-width: 767px) {
  .Blog-Card {
    width: 80%;
    gap: 2rem;
    height: max-content;
  }
}
