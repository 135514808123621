$bgColorPrimary: #fcf7e1;
$bgColorSecondary: rgba(196, 118, 16, 0.01);
$mozSelection: #967762;
$black: #000000;
$white: #ffffff;
$red: #740909;
$nameText: #4b3526;
$titleText: #ae8303;
$borderPrimary: #bbab8c;
$boxShadow: rgba(75, 53, 38, 0.2);
$postBgColor: #e4d7cc70;
$experienceColor: #01204E;
$lightBlueColor: #add8e6;
$hrBlogPost: #fffced29;

.background {
  background-color: $borderPrimary;
  color: $white;
  padding: 0 0.2rem;
}
