@import "../ColorScheme.scss";

.Contact {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  span {
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;

    a {
      text-decoration: none;
      color: $black;

      .icons {
        width: 3rem;
        height: auto;
        animation: randomBounce 7s ease-in-out infinite;
      }
    }
  }
  .leftIcons {
    margin-right: 1.5rem;
  }
  .rightIcons {
    margin-left: 1.5rem;
  }
}

@keyframes randomBounce {
  0% {
    transform: translate(var(--tx0, 0), var(--ty0, 0));
  }
  10% {
    transform: translate(var(--tx1, 5px), var(--ty1, -5px));
  }
  20% {
    transform: translate(var(--tx2, -5px), var(--ty2, 5px));
  }
  30% {
    transform: translate(var(--tx3, 7px), var(--ty3, 3px));
  }
  40% {
    transform: translate(var(--tx4, -3px), var(--ty4, -7px));
  }
  50% {
    transform: translate(var(--tx5, 4px), var(--ty5, 4px));
  }
  60% {
    transform: translate(var(--tx6, -4px), var(--ty6, -4px));
  }
  70% {
    transform: translate(var(--tx7, 3px), var(--ty7, -3px));
  }
  80% {
    transform: translate(var(--tx8, -3px), var(--ty8, 3px));
  }
  90% {
    transform: translate(var(--tx9, 5px), var(--ty9, -2px));
  }
  100% {
    transform: translate(var(--tx0, 0), var(--ty0, 0));
  }
}

@media (max-width: 767px) {
  .Contact {
    flex-direction: row;
    gap: 1.5rem;

    .leftIcons {
      margin-right: 0;
      margin-top: 1.5rem;
    }
    .rightIcons {
      margin-left: 0;
      margin-bottom: 1.5rem;
    }
  }
}

@media (min-width: 768px) and (max-width: 1025px) {
  .Contact {
    span {
      height: 12%;
    }
  }
}
