@import "../ColorScheme.scss";
.Blog-Post {
  width: 65%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  height: 100%;
  margin: 5rem 0 2rem;
  text-align: justify;
  font-family: "Montserrat";
  * {
    margin: 0.5rem 0;
  }
  img {
    width: 80%;
    max-width: 100%;
    height: auto;
    margin: 1rem 0;
  }
  .title {
    font-family: "GrandSlang-Roman";
    letter-spacing: 1px;
    width: 100%;
    text-align: center;
    color: $titleText;
    font-size: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .date {
    justify-content: flex-end;
    width: 50%;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
  }
  .categories {
    gap: 2rem;
    flex-wrap: wrap;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .category {
      color: $white;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 1.3rem;
      background-color: $red;
      box-shadow: -4px 4px 9px $boxShadow;
      width: max-content;
      padding: 0.5rem;
      rotate: -5deg;
    }
  }
  .project-link {
    a {
      color: $red;
      font-weight: 500;
      font-size: 2rem;
      text-decoration: underline;
    }
  }
  .main-post {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: left;
    line-height: 2rem;
    a {
      color: $red;
      text-decoration: none;
      font-weight: 600;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      text-align: center;
    }
    * {
      width: 100%;
    }
    hr {
      border-color: $hrBlogPost;
    }
    p {
      display: inline;
      justify-content: center;
      align-items: center;
      // text-align: justify;
    }
    img {
      width: 100%;
    }
    blockquote {
      background-color: $boxShadow;
      border-left: 5px solid $mozSelection;
      border-radius: 5px;
      padding: 1rem;
      font-style: italic;
    }
    pre {
      background: $black;
      color: $white;
      padding: 1rem;
      border-radius: 5px;
      border-top: 5px solid $lightBlueColor;
      border-left: 5px solid $lightBlueColor;
      font-size: 1rem;
    }
    table {
      border-collapse: collapse;
      text-align: center;
      width: max-content;
      thead,
      tbody {
        margin: 0;
      }
      th,
      td {
        padding: 10px;
        text-align: center;
      }
      th {
        background-color: $red;
        color: $white;
      }

      tbody tr:nth-child(odd) {
        background-color: $borderPrimary;
      }

      tbody tr:nth-child(even) {
        background-color: $bgColorPrimary;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1025px) {
  .Blog-Post {
    width: 85%;
    .title {
      font-size: 2rem;
    }
    .date {
      width: 100%;
    }
    img {
      width: 90%;
      max-width: 100%;
    }
    .project-link {
      a {
        color: $red;
        font-weight: 600;
        font-size: 1.5rem;
        text-decoration: underline;
      }
    }
  }
}

@media (max-width: 767px) {
  .Blog-Post {
    margin: 3rem 0 2rem;
    width: 85%;
    .title {
      font-size: 2rem;
    }
    .date {
      width: 100%;
    }
    img {
      width: 90%;
      max-width: 100%;
    }
    .categories {
      gap: 1rem;
      flex-wrap: wrap;
      * {
        margin: 0;
      }
      .category {
        font-size: 1rem;
      }
    }
    .project-link {
      a {
        color: $red;
        font-weight: 600;
        font-size: 1.2rem;
        text-decoration: underline;
      }
    }
    .main-post {
      text-align: left;
      img {
        width: 100%;
        align-items: center;
      }
      pre {
        overflow: scroll;
        width: 90%;
      }
      table {
        font-size: 0.8rem;
      }
    }
  }
}
